import React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './style';

interface Props {
  classes: any;
};

const Footer = ({ classes }: Props) => (
  <footer className={classes.container}>
    © {new Date().getFullYear()} Built by and for {`  `}
    <a target="_blank" href="https://www.malachai.xyz">
      {`Malachai Frazier`}
    </a> {`  `} {`chaizer.music@gmail.com`}
  </footer>
);

export default withStyles(styles)(Footer);
